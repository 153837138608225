import React from "react";
import {StocksInPortfolio} from "../../shared/components/StocksInPortfolio";
import './TradingDashboard.css';
import {PortfolioHistoryCharts} from "./PortfolioHistoryCharts";
import {StockCharts} from "./StockCharts";
import {StockChangeOnDailyBase} from "./StockChangeOnDailyBase";
import {PortfolioOverview} from "./PortfolioOverview";
import {StockPortionPieChart} from "./StockPortionPieChart";

export const TradingDashboard = () => {

    return (
        <div className="TradingDashboard">
            <React.Fragment>
                <PortfolioOverview/>
                <PortfolioHistoryCharts/>
                <StockChangeOnDailyBase/>
                <StocksInPortfolio/>
                <StockPortionPieChart/>
                <StockCharts/>
            </React.Fragment>
        </div>);
}

