import React from "react";
import {Spinner} from "../../shared/components/Spinner";
import {Chart} from "react-google-charts";
import {chartOptions} from "../../shared";
import {Link} from "react-router-dom";

export const HistoryChart = (props: { chartData: Array<any>[], name: string, changeTimeWindow: (hoursBack: number) => void, isin: string, }) =>
    <React.Fragment>
        <Spinner isLoading={props.chartData.length === 0}>
            <div className="Widget Chart">
                <h2><Link to={{pathname: `/stock/${props.isin}`}}>{props.name}</Link></h2>
                {props.isin.length > 0 ?
                    <div className="flex">
                        <a className="external-link" href={"https://www.tradegate.de/orderbuch.php?isin=" + props.isin}
                           target="_blank" rel="noopener noreferrer"><span role="img"
                                                                           aria-label="Tradegate">🔗</span> Tradegate</a>
                        <a className="external-link" href={"https://www.ls-tc.de/de/aktie/" + props.isin}
                           target="_blank"
                           rel="noopener noreferrer"><span role="img"
                                                           aria-label="Lang&Schwarz">🔗</span> Lang&Schwarz</a>
                        <a className="external-link" href={"https://news.guidants.com/#!Ticker/Feed/?txt=" + props.isin}
                           target="_blank"
                           rel="noopener noreferrer"><span role="img" aria-label="New Guidants">🔗</span> News Guidants</a>
                        <a className="external-link"
                           href={"https://www.wikifolio.com/de/de/s/" + props.isin} target="_blank"
                           rel="noopener noreferrer"><span role="img" aria-label="Wifolio">🔗</span>Wikifolio
                        </a>
                    </div> : ''}
                <div className="flex">
                    <button className="timerframe-link button-as-link"
                            onClick={() => props.changeTimeWindow(24)}>Last 24 hours
                    </button>
                    <button className="timerframe-link button-as-link"
                            onClick={() => props.changeTimeWindow(24 * 7)}>Last 7 days
                    </button>
                    <button className="timerframe-link button-as-link"
                            onClick={() => props.changeTimeWindow(24 * 31)}>Last 31 days
                    </button>
                </div>
                {
                    // During weekends no fresh data is available for last 24 hours
                    props.chartData.length === 1 ? <p>No Data available</p> :
                        <Chart
                            chartType="LineChart"
                            data={props.chartData}
                            loader={<div>Loading Chart</div>}
                            width="100%"
                            height="400px"
                            options={{
                                ...chartOptions,
                                hAxis: {
                                    textStyle: {
                                        color: "white",
                                    },
                                    gridlines: {
                                        color: "transparent",
                                    },
                                    format: "dd.MM HH:mm",
                                }
                            }}
                        />
                }
            </div>
        </Spinner>
    </React.Fragment>;
