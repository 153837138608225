import React, {ChangeEvent, useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import "./dashboard/PortfolioHistoryCharts.css";
import {PortfolioHistoryEntry} from "../shared/Domain";
import {chartOptions} from "../shared";
import {Spinner} from "../shared/components/Spinner";
import {ProfitLossOverview} from "../shared/components/ProfitLossOverview";
import {AzureBackend} from "../shared/AzureBackend";

export const PortfolioHistoryPerYear = () => {

    const [from, setFrom] = useState<Date>(new Date(2025, 0, 1));
    const [to, setTo] = useState<Date>(new Date());

    const [lastYear, setLastYearData] = React.useState(new Array<Array<any>>());
    const [absoluteChangeOnly, setAbsoluteChangeOnly] = React.useState(true);
    const [year, setYear] = React.useState<number>(new Date().getFullYear());

    useEffect(() => {
        const mapToChartData = (portfolioHistoryEntries: PortfolioHistoryEntry[]) => {
            const mappedData: Array<Array<any>> = portfolioHistoryEntries.map((ph) => {
                return absoluteChangeOnly
                    ? [new Date(ph.date), ph.currentValueOfStocksInEuro - ph.amountInvested]
                    : [new Date(ph.date), ph.portfolioValue, 0, ph.portfolioValue];
            });
            return mappedData;
        };

        new AzureBackend().fetchPortfolioHistory(from, to).then((history) => {
                const firstDataRow = absoluteChangeOnly ? [["0", "Total"]] : [["0", "Stocks", "Cash", "Total"]];
                setLastYearData(firstDataRow.concat(mapToChartData(history.portfolioHistoryEntries)));
            }
        );

    }, [absoluteChangeOnly, from, to]);

    const switchTotalValueOnly = () => setAbsoluteChangeOnly(!absoluteChangeOnly);

    function changeYear(event: ChangeEvent<HTMLSelectElement>) {
        const yearSelected = +event.target.value;
        setYear(yearSelected);
        if (yearSelected === 0) {
            setFrom(new Date(2018, 0, 1));
            setTo(new Date());
        } else {
            setFrom(new Date(yearSelected, 0, 1));
            setTo(new Date(yearSelected + 1, 0, 1));
        }
    }

    return (
        <Spinner isLoading={lastYear.length === 0}>
            <div>
                <select onChange={changeYear} name="years" id="years">
                    <option value="2016" selected={year === 2016}>2016</option>
                    <option value="2017" selected={year === 2017}>2017</option>
                    <option value="2018" selected={year === 2018}>2018</option>
                    <option value="2019" selected={year === 2019}>2019</option>
                    <option value="2020" selected={year === 2020}>2020</option>
                    <option value="2021" selected={year === 2021}>2021</option>
                    <option value="2022" selected={year === 2022}>2022</option>
                    <option value="2023" selected={year === 2023}>2023</option>
                    <option value="2024" selected={year === 2024}>2024</option>
                    <option value="2025" selected={year === 2025}>2025</option>
                    <option value="0" selected={year === 0}>total</option>
                </select>
            </div>
            <div className="Widget Chart">
                <div>Total value only: <input type="checkbox" onChange={switchTotalValueOnly}
                                              defaultChecked={absoluteChangeOnly}/></div>
                <Chart
                    chartType="LineChart"
                    data={lastYear}
                    width="100%"
                    height="400px"
                    options={{
                        title: "last Year",
                        ...chartOptions,
                        series: {
                            0: {color: 'rgb(85,113,111)'},
                            1: {color: 'rgb(115,20,130)'},
                            2: {color: 'rgb(48, 135, 180)'},
                        },
                    }}
                />
            </div>
            <div className="Widget Chart">
                <ProfitLossOverview year={year}/>
            </div>
        </Spinner>
    );
}
