import React, {useEffect} from "react";
import {numberFormat} from "../../shared";
import {Stock} from "../../shared/shared";

export const StockPriceTable = (props: { stocks: Stock[] }) => {

    const [sortedStocks, setSortedStocks] = React.useState<Stock[]>([]);


    useEffect(() => {
        setSortedStocks(props.stocks.slice().sort(sortByChange()));
    }, [props.stocks]);

    const sortByName = () => (l: Stock, r: Stock) => l.name.localeCompare(r.name);
    const sortByChange = () => (l: Stock, r: Stock) => (r.changeRelative() - l.changeRelative());
    const setStocksSortedByName = () => setSortedStocks(props.stocks.slice().sort(sortByName()));
    const setStocksSortedByChange = () => setSortedStocks(props.stocks.slice().sort(sortByChange()));

    return <table>
        <thead>
        <tr>
            <td className="ColumnWithSpace" onClick={setStocksSortedByName}>Name</td>
            <td className="ColumnWithSpace">Price</td>
            <td className="ColumnWithSpace" onClick={setStocksSortedByChange}>Change</td>
        </tr>
        </thead>
        <tbody>{sortedStocks.map((s: Stock) =>
            <tr className="HoverRow" key={s.isin}>
                <td className="ColumnWithSpace">{s.name}</td>
                <td className="ColumnWithSpace">{numberFormat.format(s.currentPrice)}</td>
                <td
                    className={(s.changeAbsolute() > 0 ? " changePositive" : "changeNegative") + " ColumnWithSpace"}> {s.changeRelative().toFixed(2)} %
                </td>
            </tr>)}
        </tbody>
    </table>
}
