import React from 'react';
import {Investment} from "../Domain";
import {v4 as uuid} from "uuid";
import {numberFormat as nf} from "../../shared";
import "./InvestmentsList.css";
import {Link} from "react-router-dom";
import {useStocks} from "../StocksContext";

export interface InvestmentsProps {
    investments: Investment[]
}

const positiveChange = (s: Investment) => ((s.quantity * s.latestPriceInEuro) - s.investedSumInEuro) > 0;
const changeTotal = (s: Investment) => nf.format((s.quantity * s.latestPriceInEuro) - s.investedSumInEuro);
const changeInPercentage = (s: Investment): string => ((((s.quantity * s.latestPriceInEuro) / s.investedSumInEuro) * 100) - 100).toFixed(2) + " %";

const InvestmentItem = (props: { investment: Investment }) => {

    const [, setDialogOpen] = React.useState(false);
    const {stocks,} = useStocks();

    const openDialog = () => setDialogOpen(true);

    return <li className="StockListItem">
                <span>
                    {stocks.find(s => s.isin === props.investment.isin)?.name}: {nf.format(props.investment.quantity * props.investment.latestPriceInEuro)}
                </span>
        <span
            className={props.investment.quantity * props.investment.latestPriceInEuro > props.investment.investedSumInEuro ? "large Positive" : "large Negative"}>
            {positiveChange(props.investment) ? "↗" : "↘"} {changeTotal(props.investment)} / {changeInPercentage(props.investment)}
        </span>
        <br/>
        <span>Buy-In Total: {nf.format(props.investment.investedSumInEuro)} - Buy-In per share: {nf.format(props.investment.buyInPerShareInEuro)} - Number: {props.investment.quantity} - Price: {nf.format(props.investment.latestPriceInEuro)}</span>
        {props.investment.transactions.length > 1 ? props.investment.transactions.map(t => {
            return <span key={t.id}
                         className="block">-&gt; {new Date(t.date).toLocaleDateString("de-DE")}: {t.quantity} - {t.price} € - {t.type}</span>
        }) : ""}
        <div className="linkContainer">
            <a className="external-link" href={"https://www.tradegate.de/orderbuch.php?isin=" + props.investment.isin}
               target="_blank" rel="noopener noreferrer"><span role="img" aria-label="Tradegate">🔗</span> Tradegate</a>
            <a className="external-link" href={"https://www.ls-tc.de/de/aktie/" + props.investment.isin} target="_blank"
               rel="noopener noreferrer"><span role="img" aria-label="Lang&Schwarz">🔗</span> Lang&Schwarz</a>
            <a className="external-link" href={"https://news.guidants.com/#!Ticker/Feed/?txt=" + props.investment.isin}
               target="_blank"
               rel="noopener noreferrer"><span role="img" aria-label="New Guidants">🔗</span> News Guidants</a>
            <a className="external-link"
               href={"https://www.wikifolio.com/de/de/s/" + props.investment.isin} target="_blank"
               rel="noopener noreferrer"><span role="img" aria-label="Wifolio">🔗</span>Wikifolio
            </a>
            <Link className="external-link" to={{pathname: `/stock/${props.investment.isin}`}} target="_blank">View
                stock</Link>
        </div>
        <div>
            <button onClick={openDialog}>Sell</button>
        </div>
    </li>;
};

export const InvestmentsList = (props: InvestmentsProps) => {

    const investments = props.investments?.slice().sort((a, b) =>
        b.quantity * b.latestPriceInEuro - a.quantity * a.latestPriceInEuro).map(s => (
        <InvestmentItem key={uuid()} investment={s}/>)
    )

    const totalCurrentValue = props.investments.reduce((sum, currentInvestment) =>
        sum + (currentInvestment.latestPriceInEuro * currentInvestment.quantity), 0);

    return (
        <div>
            <div className="greenLarge">Total current value: {nf.format(totalCurrentValue)}</div>
            <ul className="InvestmentsList">{investments}</ul>
        </div>
    );

}
