import "./StockConfiguration.css";
import {useStocks} from "../shared/StocksContext";

export function StockConfiguration() {
    const {stocks, loading, error} = useStocks();

    return <div>
        <h1>Stock Configuration</h1>
        {error && <p>Error :(</p>}
        {loading && <p>Loading...</p>}
        {stocks.length > 0 && <table className="Widget">
            <thead>
            <tr>
                <th>Name</th>
                <th>ISIN</th>
                <th>Show on Dashboard</th>
                <th>Latest Price (€)</th>
                <th>Is collection prices</th>
            </tr>
            </thead>
            <tbody>
            {stocks.sort((a, b) => a.name.localeCompare(b.name)).map(s => (
                <tr key={s.isin} className="HoverRow">
                    <td>{s.name}</td>
                    <td>{s.isin}</td>
                    <td>{s.isShownOnDashboard ? "✅" : ""}</td>
                    <td style={{textAlign: "right"}}>{s.priceInEuro} €</td>
                    <td>{s.isCollectingPrices ? "✅" : ""}</td>
                </tr>
            ))}
            </tbody>
        </table>}
    </div>;
}

