import React from "react";
import {numberFormat as nf} from "../../shared";
import {Portfolio} from "../../shared/Domain";
import {AzureBackend} from "../../shared/AzureBackend";
import {useStocks} from "../../shared/StocksContext";

export function AccountPage(props: { id: string, name?: string }) {

    const {stocks, } = useStocks();
    const [portfolio, setPortfolio] = React.useState<Portfolio>({
        investments: [],
        buySumInEuro: 0,
        sellSumInEuro: 0,
        currentProfitLossInEuro: 0,
        taxesPayedInEuro: 0,
        dividendSumInEuro: 0,
        investedSumInEuro: 0,
        currentValueOfStocksInEuro: 0
    });


    const [changeAbsolute, setChangeAbsolute] = React.useState<number>(0);
    const [changeInPercentage, setChangeInPercentage] = React.useState<number>(0);

    React.useEffect(() => {
            const azureBackend = new AzureBackend();

            if (props.id === '') return;

            azureBackend.fetchPortfolioByAccountId(props.id).then(data => {
                setPortfolio(data);
                setChangeAbsolute(data.currentValueOfStocksInEuro - data.investedSumInEuro);
                setChangeInPercentage((data.currentValueOfStocksInEuro - data.investedSumInEuro) / data.investedSumInEuro);
            });
        }
        , [props.id]);

    if (props.id === '') return (<p>Loading...</p>)


    return <div>
        <h1>{props.name}</h1>
        <div className="Widget">
            <div>sum buy: {nf.format(portfolio.buySumInEuro)}</div>
            <div>sum sell: {nf.format(portfolio.sellSumInEuro)}</div>
            <div>sum dividend: {nf.format(portfolio.dividendSumInEuro)}</div>
            <div>sum taxes: {nf.format(portfolio.taxesPayedInEuro)}</div>

            <div
                className={portfolio.currentProfitLossInEuro > 0 ? "changePositive" : "changeNegative"}>Current
                P/R: {nf.format(portfolio.currentProfitLossInEuro)}
            </div>

            <div className="topBuffer">Invested sum: {nf.format(portfolio.investedSumInEuro)}</div>
            <div className="topBuffer">Current value: {nf.format(portfolio.currentValueOfStocksInEuro)}</div>

            <div className={changeAbsolute > 0 ? "changePositive" : "changeNegative"}>
                Change: {changeAbsolute > 0 ? "↗" : "↘"} {nf.format(changeAbsolute)}
            </div>
            <div className={changeAbsolute > 0 ? "changePositive" : "changeNegative"}>Change in
                %: {(changeInPercentage * 100).toFixed(2)} %
            </div>
        </div>
        <div className="Widget">
            <h2>Investments</h2>
            {portfolio.investments.map(investment => (
                <div key={investment.isin} className="Investment">
                    <h3>{stocks.find(stock => stock.isin === investment.isin)?.name || investment.isin}</h3>
                    <div>Latest Price: {nf.format(investment.latestPriceInEuro)}</div>
                    <div>Current Value: {nf.format(investment.currentValueInEuro)}</div>
                    <div>Invested Sum: {nf.format(investment.investedSumInEuro)}</div>
                    <div>Buy-In Per Share: {nf.format(investment.buyInPerShareInEuro)}</div>
                    <div>Quantity: {investment.quantity}</div>
                    <h4>Transactions</h4>
                    {investment.transactions
                        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                        .map(transaction => (
                            <div key={transaction.id} className="Transaction">
                                {transaction.date} - {transaction.type} - {nf.format(transaction.price)} - {transaction.quantity} - {nf.format(transaction.totalCosts)}
                            </div>
                        ))}
                </div>
            ))}
        </div>
    </div>;

}