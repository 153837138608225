import React, {useEffect} from "react";
import {getLast24Hours} from "../../shared";
import {StockPriceHistoryDto} from "../../shared/Domain";
import "./StockTrendLineChart.css";
import {HistoryChart} from "./HistoryChart";
import {AzureBackend} from "../../shared/AzureBackend";

export const StockTrendLineChart = (props: { isin: string }) => {

    const [error, setError] = React.useState(false);
    const [to] = React.useState<Date>(new Date());
    const [from, setFrom] = React.useState<Date>(getLast24Hours());
    const [chartData, setChartData] = React.useState(new Array<Array<any>>());
    const [name, setName] = React.useState("");

    useEffect(() => {
        const mapToChartData = (priceHistoryDtos: StockPriceHistoryDto[]) => {
            const mappedData: Array<Array<any>> = priceHistoryDtos.map((ph: StockPriceHistoryDto) => {
                return [new Date(ph.date), ph.priceInEuro]
            });
            return mappedData;
        };

        const azureBackend = new AzureBackend();
        azureBackend.fetchStockPriceHistory(props.isin, from, to)
            .then(data => {
                setChartData([["0", "Value"]].concat(mapToChartData(data)));
                if (data.length > 0) {
                    setName(data[0].name);
                }
            })
            .catch((reason) => {
                setError(true);
                console.error(reason);
            });
    }, [props.isin, from, to]);

    if (error) return <p>Error :(</p>;

    const changeTimeframe = (hoursBack: number) => {
        const newFromDate = new Date(to);
        newFromDate.setHours(newFromDate.getHours() - hoursBack)
        setFrom(newFromDate);
    };

    return <HistoryChart chartData={chartData} isin={props.isin} name={name} changeTimeWindow={changeTimeframe}/>;
};
