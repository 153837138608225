import React, {useEffect} from "react";
import {Transaction} from "../Domain";
import {numberFormat} from "../../shared";
import "./ProfitLossOverview.css"
import {Link} from "react-router-dom";
import {AzureBackend} from "../AzureBackend";
import {useStocks} from "../StocksContext";

type TransactionWithProfitLoss = Transaction & { profitLoss: number };

export const ProfitLossOverview = (props: { year: number }) => {

    const {stocks,} = useStocks();
    const [transactionsWithProfitLoss, setTransactionsWithProfitLoss] = React.useState<TransactionWithProfitLoss[]>([]);
    const [profitLoss, setRealizedProfitLoss] = React.useState(0);

    useEffect(() => {
        const initialYear = new Date(2015, 0, 1);
        const from = props.year === 0 ? initialYear : new Date(props.year, 0, 1);
        const to = props.year === 0 ? initialYear : new Date(props.year, 0, 1);
        new AzureBackend().fetchAllTransactions(undefined, undefined, from, to)
            .then((transactions) => {
                    const transactionsThisYear = props.year === 0
                        ? transactions.filter((t: Transaction) => t.type !== "BUY")
                        : transactions.filter((t: Transaction) => new Date(t.date).getFullYear() === props.year && t.type !== "BUY");
                    transactionsThisYear.sort((a, b) => a.date.getTime() - b.date.getTime());

                    const promises = transactionsThisYear.map((tty) => {
                        if (tty.type === "SELL") {
                            return new AzureBackend().fetchProfitLossForTransaction(tty.id!);
                        }
                        const profitAbsolute = tty.price * tty.quantity - tty.totalCosts;
                        return Promise.resolve(tty.type === "TAXES" ? -profitAbsolute : profitAbsolute);
                    });
                    Promise.all(promises).then((profitLosses) => {
                        const transactionsWithProfitLoss = transactionsThisYear.map((t, index) => {
                            return {...t, profitLoss: profitLosses[index]};
                        });
                        setTransactionsWithProfitLoss(transactionsWithProfitLoss);
                        setRealizedProfitLoss(transactionsWithProfitLoss.reduce((acc, t) => acc + t.profitLoss, 0));
                    });
                }
            );
    }, [props.year]);

    return (
        <React.Fragment>
            <table>
                {transactionsWithProfitLoss.map((t) => {
                    return (
                        <tbody key={t.id}>
                        <tr className="transaction-row">
                            <td>{t.date.toLocaleDateString("de-DE")}</td>
                            <td><Link to={{pathname: `/stock/${t.isin}`}}>{t.isin}</Link></td>
                            <td>{stocks.find(s => s.isin === t.isin)?.name}</td>
                            <td>{numberFormat.format(t.price)}</td>
                            <td>{numberFormat.format(t.quantity)}</td>
                            <td>{numberFormat.format(t.totalCosts)}</td>
                            <td>{t.type}</td>
                            <td className={t.profitLoss > 0 ? "changePositive" : "changeNegative"}>
                                {numberFormat.format(t.profitLoss)}</td>
                        </tr>
                        </tbody>)
                })}
            </table>
            <div><span
                className={profitLoss > 0 ? "changePositive" : "changeNegative"}>P/L: {numberFormat.format(profitLoss)}</span>
            </div>
        </React.Fragment>
    );
};
