import React, {useEffect} from "react";
import {Investment, StockDto, Transaction} from "../shared/Domain";
import {StockTrendLineChart} from "./dashboard/StockTrendLineChart";
import {numberFormat as nf, numberFormat} from "../shared";
import {AzureBackend} from "../shared/AzureBackend";
import {useStocks} from "../shared/StocksContext";


export const StockInfo = (props: any) => {

    const {stocks, loading} = useStocks();
    const [transactions, setTransactions] = React.useState<Transaction[]>([]);
    const [investment, setInvestment] = React.useState<Investment>({
        isin: "",
        quantity: 0,
        latestPriceInEuro: 0,
        investedSumInEuro: 0,
        buyInPerShareInEuro: 0,
        currentValueInEuro: 0,
        transactions: []
    });
    const [stock, setStock] = React.useState<StockDto | undefined>(undefined);

    useEffect(() => {
        const isin = props.match.params.isin;
        new AzureBackend().fetchPortfolioData().then(portfolio => {
            const investment = portfolio.investments.find(i => i.isin === isin);
            if (!investment) {
                console.error("Investment not found for isin " + isin);
                return;
            }
            setInvestment(investment);
            setTransactions(investment.transactions);
        });
        if (!loading) {
            const stock = stocks.find(s => s.isin === props.match.params.isin);
            if (!stock) {
                console.error("Stock not found for isin " + props.match.params.isin);
                setStock(stock);
                return;
            }

        }
    }, [props.match.params.isin, stocks, loading]);

    return <React.Fragment>
        <h1>{stock && stock.name}</h1>
        <div className="Widget">
            <div>current price: {nf.format(investment.latestPriceInEuro)}</div>
            <div>quantity: {investment.quantity}</div>
            <div>sum invested: {nf.format(investment.investedSumInEuro)}</div>
            <div>Buy in per share: {nf.format(investment.buyInPerShareInEuro)}</div>
            <div>Current Value: {nf.format(investment.currentValueInEuro)}</div>

            <div
                className={investment.currentValueInEuro > investment.investedSumInEuro ? " changePositive" : "changeNegative"}>Total
                P/L: {nf.format(investment.currentValueInEuro - investment.investedSumInEuro)}
                / {nf.format((investment.currentValueInEuro - investment.investedSumInEuro) / investment.investedSumInEuro * 100)}%
            </div>
        </div>
        {props.match.params.isin && <StockTrendLineChart isin={props.match.params.isin}/>}
        {transactions.length > 0 && <h2>My Transactions</h2>}
        <ul>{transactions.map(t => (
            <li key={t.id}>{t.date}:
                ({numberFormat.format(t.price)} * {t.quantity})
                - {numberFormat.format(t.totalCosts)} - {t.type}</li>
        ))}</ul>
    </React.Fragment>
};

