import React, {useEffect} from 'react';
import {StockTrendLineChart} from "./StockTrendLineChart";
import {StockDto} from "../../shared/Domain";
import {AzureBackend} from "../../shared/AzureBackend";

export const StockCharts = () => {

    const [stocks, setStocks] = React.useState(new Array<StockDto>());
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const azureBackend = new AzureBackend();
        azureBackend.fetchAllStocks().then(data => {
            setStocks(data);
            setLoading(false);
        }).catch(() => {
            setError(true);
            setLoading(false);
        });
    }, []);

    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading :(</p>)

    return (
        <React.Fragment>
            {stocks
                .filter((s: StockDto) => s.isin.length > 0 && s.isShownOnDashboard)
                .sort((left: StockDto, right: StockDto) => left.name.toLowerCase() > right.name.toLowerCase() ? 1 : -1)
                .map((os: StockDto) => <StockTrendLineChart key={os.id} isin={os.isin}/>)}
        </React.Fragment>
    )
};
