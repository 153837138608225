// Define a class that repesents the rest backend of the application.
// The class has a method that fetches the portfolio data from the backend.

import {Account, Label, Portfolio, PortfolioHistory, StockDto, StockPriceHistoryDto, Transaction} from "./Domain";

export class AzureBackend {

    private static host = 'https://stockmonitor-backend-cbaqbqdagebxcfb4.germanywestcentral-01.azurewebsites.net';
    // private static host = 'http://localhost:8080';
    private basicAuth = 'Basic ' + btoa(localStorage.getItem('azure_user') + ":" + localStorage.getItem('azure_password'));

    async fetchPortfolioData(): Promise<Portfolio> {
        let response = await fetch(AzureBackend.host + "/portfolio/total", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchPortfolioByAccountId(accountId: string): Promise<Portfolio> {
        let response = await fetch(`${AzureBackend.host}/portfolio/account?accountId=${accountId}`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchAllAccounts(): Promise<Account[]> {
        const response = await fetch(AzureBackend.host + "/accounts", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json()
    }

    async fetchAllTransactions(accountId?: string, isin?: string, from?: Date, to?: Date): Promise<Transaction[]> {
        const url = new URL(AzureBackend.host + "/accounts/transactions");
        if (accountId) url.searchParams.append("accountId", accountId);
        if (isin) url.searchParams.append("isin", isin);
        if (from) url.searchParams.append("from", from.toISOString());
        if (to) url.searchParams.append("to", to.toISOString());

        const response = await fetch(url.toString(), {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        const transactions: Transaction[] = await response.json();
        return transactions.map(transaction => ({
            ...transaction,
            date: new Date(transaction.date)
        }));
    }

    async deleteTransaction(transactionId: string): Promise<void> {
        await fetch(`${AzureBackend.host}/accounts/transactions?transactionId=${transactionId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
    }

    async fetchAllStocks(): Promise<StockDto[]> {
        const response = await fetch(AzureBackend.host + "/stocks", {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchStockPriceHistory(isin: string, from: Date, to: Date): Promise<StockPriceHistoryDto[]> {

        const response = await fetch(`${AzureBackend.host}/stocks/prices?isin=${isin}&from=${from.toISOString()}&to=${to.toISOString()}`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async saveTransaction(transactionData: Transaction, existingTransaction: Transaction | undefined): Promise<Transaction> {
        const method = existingTransaction ? "PUT" : "POST";
        const url = `${AzureBackend.host}/accounts/transactions`;

        const response = await fetch(url, {
            method,
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...transactionData,
                date: transactionData.date.toISOString().split('T')[0]
            }),
        });

        const transaction = await response.json();
        transaction.date = new Date(transaction.date);
        return transaction;
    }


    async fetchPortfolioHistory(from: Date, to: Date): Promise<PortfolioHistory> {
        const response = await fetch(`${AzureBackend.host}/portfolio/history?from=${(from.toISOString())}&to=${(to.toISOString())}`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchProfitLossForTransaction(transactionId: string): Promise<number> {
        const response = await fetch(`${AzureBackend.host}/accounts/transactions/${transactionId}/profit-loss`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return response.json();
    }

    async fetchAllLabels(): Promise<Label[]> {
        const response = await fetch(`${AzureBackend.host}/stocks/labels`, {
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
        return (await response.json()).map((d: any) => ({
            id: d.id.id,
            key: d.key,
            value: d.value
        }));
    }

    async importStockPrices(): Promise<Response> {
        return fetch(`${AzureBackend.host}/stocks/prices/refresh`, {
            method: 'GET',
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });
    }

    async freezePortfolioValue(): Promise<Response> {
        return fetch(`${AzureBackend.host}/portfolio/freeze`, {
            method: 'GET',
            headers: {
                "Authorization": this.basicAuth,
                "Content-Type": "application/json",
            }
        });

    }
}