import React, {createContext, useContext, useEffect, useState} from 'react';
import {AzureBackend} from "./AzureBackend";
import {StockDto} from "./Domain";

interface StocksContextType {
    stocks: StockDto[];
    loading: boolean;
    error: string | null;
}

const StocksContext = createContext<StocksContextType | undefined>(undefined);

export const StocksProvider: React.FC = ({ children }) => {
    const [stocks, setStocks] = useState<StockDto[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const azureBackend = new AzureBackend();
                const stocksData = await azureBackend.fetchAllStocks();
                setStocks(stocksData);
            } catch (err) {
                setError('Failed to load stocks');
            } finally {
                setLoading(false);
            }
        };

        fetchStocks();
    }, []);

    return (
        <StocksContext.Provider value={{ stocks, loading, error }}>
    {children}
    </StocksContext.Provider>
);
};

export const useStocks = (): StocksContextType => {
    const context = useContext(StocksContext);
    if (context === undefined) {
        throw new Error('useStocks must be used within a StocksProvider');
    }
    return context;
};