import {Account, StockDto, Transaction} from "./Domain";

export interface TransactionFormProps {
    transaction?: Transaction;
    accounts: Account[];
    stocks: StockDto[];
    onSave: (transaction: Transaction) => void;
}

export class Stock {
    constructor(public isin: string, public name: string, public currentPrice: number, public priceDayBefore: number) {
    }

    static fromDto(dto: StockDto): Stock {
        return new Stock(dto.isin, dto.name, dto.priceInEuro, dto.priceInEuroDayBefore);
    }

    changeAbsolute(): number {
        return this.currentPrice - this.priceDayBefore;
    }

    changeRelative(): number {
        return this.changeAbsolute() / this.priceDayBefore * 100;
    }
}
