import React from "react";
import {AzureBackend} from "../shared/AzureBackend";

export const Commands = () => {

    const [lastExecutionState, setLastExecutionState] = React.useState<String>("no execution yet");

    const executeCommandOnClick = (e: React.MouseEvent<HTMLButtonElement>, commandName: string): void => {
        e.preventDefault();
        setLastExecutionState("running");
        const backend = new AzureBackend();

        const executeCommand = (command: () => Promise<Response>) => {
            command()
                .then(() => setLastExecutionState("Success"))
                .catch((reason) => {
                    console.error(reason);
                    setLastExecutionState("Error");
                });
        };

        if (commandName === "importStockPrices") {
            executeCommand(() => backend.importStockPrices());
        } else if (commandName === "freezePortfolioValue") {
            executeCommand(() => backend.freezePortfolioValue());
        }
    }

    return (
        <React.Fragment>
            <div>
                <button onClick={(e) => executeCommandOnClick(e, "importStockPrices")}>
                    import stock prices
                </button>
            </div>
            <div>
                <button onClick={(e) => executeCommandOnClick(e, "freezePortfolioValue")}>
                    freeze portfolio
                </button>
            </div>
            <h2>last execution</h2>
            <div>
                <div>State: {lastExecutionState}</div>
            </div>
        </React.Fragment>
    )
};
