import React, {useEffect} from "react";
import {Investment} from "../Domain";
import {Spinner} from "./Spinner";
import {InvestmentsList} from "./InvestmentsList";
import {v4 as uuid} from "uuid";
import {AzureBackend} from "../AzureBackend";

export const StocksInPortfolio = () => {

    const [investments, setInvestments] = React.useState<Investment[]>([]);
    const [keyIdToTriggerAnimation, setKeyIdToTriggerAnimation] = React.useState<string>(uuid());

    useEffect(() => {
        new AzureBackend().fetchPortfolioData()
            .then(data => {
                setKeyIdToTriggerAnimation(uuid());
                setInvestments(data.investments);
            })
            .catch(e => console.error(e));
    }, []);


    return (
        <Spinner isLoading={investments?.length === 0}>
            <div key={keyIdToTriggerAnimation} className="Widget greenBackgroundFadeOut">
                <InvestmentsList investments={investments}/>
            </div>
        </Spinner>
    );
}
