
export interface Label {
    id: string;
    key: string;
    value: string;
}

export enum TransactionType {
    BUY = "BUY", SELL = "SELL", DIVIDEND = "DIVIDEND", TAXES = "TAXES"
}

export interface Investment {
    isin: string,
    latestPriceInEuro: number,
    currentValueInEuro: number,
    investedSumInEuro: number,
    buyInPerShareInEuro: number,
    quantity: number,
    transactions: Transaction[]
}

export interface Portfolio {
    currentValueOfStocksInEuro: number,
    currentProfitLossInEuro: number,
    sellSumInEuro: number,
    buySumInEuro: number,
    dividendSumInEuro: number,
    taxesPayedInEuro: number,
    investedSumInEuro: number,
    investments: Investment[]
}

export interface PortfolioHistory {
    portfolioHistoryEntries: PortfolioHistoryEntry[]
}

export interface PortfolioHistoryEntry {
    date: Date,
    amountInvested: number,
    portfolioValue: number,
    changeInPercentage: number,
    currentValueOfStocksInEuro: number
}

export interface Transaction {
    id?: string;
    accountId: string;
    isin: string;
    date: Date;
    price: number; // Euro as number
    quantity: number; // BigDecimal as number
    totalCosts: number; // Euro as number
    type: string; // TransactionType as string
}

export interface Account {
    id: string;
    name: string;
}

export interface StockDto {
    id: string;
    isin: string;
    name: string;
    priceInEuro: number;
    priceInEuroDayBefore: number;
    isShownOnDashboard: boolean;
    isCollectingPrices: boolean;
}

export interface StockPriceHistoryDto {
    date: Date;
    priceInEuro: number;
    isin: string;
    name: string;
}
