export const numberFormat = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'});

export const chartOptions = {
    colors: ['white'],
    chartLanguage: 'de',
    backgroundColor: 'black',
    chartArea: {width: '70%', height: '75%'},
    titleTextStyle: {
        color: 'white',
    },
    series: {
        0: {color: 'rgb(48, 135, 180)'},
    },
    legend: {position: 'none'},
    legendTextStyle: {
        color: 'white',
    },
    hAxis: {
        textStyle: {
            color: 'white',
        },
        gridlines: {
            color: 'transparent',
        },
        format: 'd.MM.YY',
    },
    vAxis: {
        textStyle: {
            color: 'white'
        },
        gridlines: {
            color: "rgb(68,68,68)",
        },
        format: 'decimal'
    }
}

export const getLast24Hours = (): Date => {
    const date = new Date();
    date.setHours(date.getHours() - 24);
    return date;
};
