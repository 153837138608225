import React from 'react';
import './App.css';
import {TradingDashboard} from "./pages/dashboard/TradingDashboard";
import {HashRouter as Router, Link, Route, Switch} from 'react-router-dom';
import {PortfolioHistoryPerYear} from "./pages/PortfolioHistoryPerYear";
import {StockConfiguration} from "./pages/StockConfiguration";
import {Commands} from "./pages/Commands";
import {StockInfo} from "./pages/StockInfo";
import {Accounts} from "./pages/accounts/Accounts";
import {Labels} from "./pages/Labels";
import {Config} from "./pages/Config";
import {Transactions} from "./pages/transactions/Transactions";
import {StocksProvider} from "./shared/StocksContext";

function App() {
    return (
        <StocksProvider>
            <Router>
                <div className="App">
                    <nav>
                        <ul id="navigation">
                            <li className="navigation-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/lastYear">YTD</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/stockConfiguration">Stocks</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/accounts">Accounts</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/labels">Labels</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/transactionsAzure">Transactions</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/commands">Commands</Link>
                            </li>
                            <li className="navigation-item">
                                <Link to="/config">Config</Link>
                            </li>
                        </ul>
                    </nav>

                    <Switch>
                        <Route path="/lastYear"> <PortfolioHistoryPerYear/> </Route>
                        <Route path="/stockConfiguration"> <StockConfiguration/> </Route>
                        <Route path="/transactionsAzure"> <Transactions/> </Route>
                        <Route path="/commands"> <Commands/> </Route>
                        <Route path="/accounts"> <Accounts/> </Route>
                        <Route path="/labels"> <Labels/> </Route>
                        <Route path="/stock/:isin" component={StockInfo}/>
                        <Route path="/config"> <Config/> </Route>
                        <Route path="/">
                            <TradingDashboard/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </StocksProvider>
    );
}

export default App;
