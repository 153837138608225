import React, {useEffect} from "react";
import {AzureBackend} from "../../shared/AzureBackend";
import {Account} from "../../shared/Domain";
import {AccountPage} from "./AccountPage";

export function Accounts() {

    const [accountName, setAccountName] = React.useState("ING DIBA Stefan");
    const [accountId, setAccountId] = React.useState("");
    const [accounts, setAccounts] = React.useState<Account[]>([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
        new AzureBackend().fetchAllAccounts().then(data => {
            setAccounts(data);
            setAccountId(data[0].id);
            setAccountName(data[0].name);
            setLoading(false);
        }).catch(() => {
            setError(true);
        });
    }, []);

    return <div>
        <h1>Accounts</h1>
        {error && <p>Error :(</p>}
        {loading && <p>Loading...</p>}
        {accounts.length > 0 && accounts.map((a: {
            id: string;
            name: string
        }) => <button key={a.name}
                      onClick={() => {
                          setAccountName(a.name);
                          setAccountId(a.id);
                      }}>{a.name}
        </button>)}
        <br/>
        {accountName.length > 0 && <AccountPage id={accountId} name={accountName}/>}

    </div>;
}
