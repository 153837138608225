import React, {useEffect} from "react";
import {Label} from "../shared/Domain";
import {AzureBackend} from "../shared/AzureBackend";

export function Labels() {

    const [, setCurrentLabel] = React.useState<{ id: string, name: string }>({id: "", name: ""});
    const [labels, setLabels] = React.useState<Label[]>([]);

    useEffect(() => {
        new AzureBackend().fetchAllLabels().then(labels => {
            const sortedLabels = labels.slice().sort((l: Label, r: Label) => (l.key + l.value).localeCompare((r.key + r.value)));
            setLabels(sortedLabels);
        });
    }, []);

    return <div>
        <h1>Labels</h1>
        <div>
            {labels.map((l: Label) => <button key={l.id}
                                              onClick={() => setCurrentLabel({
                                                  id: l.id,
                                                  name: `${l.key}:${l.value}`
                                              })}>{l.key}:{l.value}</button>)}
        </div>
    </div>;
}
